@import "./variables";
@import "./mediaquery";

.terms {
  .top {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;
    margin: 0 auto 1em auto;
    background-color: $primary;
    padding: 10px 7.5vw 10px 7.5vw;

    .logo {
      display: grid;
      grid-template-columns: auto auto;
      width: fit-content;
      color: white;
      gap: 10px;

      img {
        max-height: 54px;
        height: 100%;
        object-fit: contain;
      }
    }

    .nav {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;

      h4 {
        cursor: pointer;
        color: white;
        font-weight: 500;
        font-size: small;
        margin: auto;
      }
    }
  }

  .container {
    max-width: 85%;
    width: 100%;
    margin: auto;

    ul {
      margin-left: 2em;
    }

    h1 {
      margin: 1em 0 1em 0;
      color: $primary;
      text-align: center;
    }

    h2,
    h3{
      font-weight: 520;
    }

    h2,
    h3,
    li,
    p {
      margin: 1.5em 0 1em 0 !important;
      font-size: medium !important;
      line-height: 1.5 !important;
    }
  }
}
