@import "./variables";
@import "./mediaquery";

.voters {
  height: 100%;
  width: 100%;
  padding: 1em;

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 0 0 5px 5px;
    position: relative;

    .loading {
      position: fixed !important;
      background-color: transparent !important;
    }

    hr {
      color: #60606010;
      margin: 10px 0 10px 0;
    }

    .utp {
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 1em;

      h3 {
        color: $secondary;
        font-size: medium;
        font-weight: 520;
        display: flex;
        align-items: center;
      }

      p {
        font-size: small;
        color: white;
        background-color: $secondary;
        border-radius: 5px;
        border: 1px solid $secondary;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .fa-add {
          margin-right: 5px;
        }
      }
      p:hover {
        color: $secondary;
        background-color: white;
      }

      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          padding: 10px;
          display: flex;
          align-items: center;
          color: #60606050;
        }

        input {
          width: 100%;
          padding: 10px;
          outline: none;
          border: 1px solid #60606050;
          font-size: small;
          border-radius: 5px;
        }
      }
    }

    .div31 {
      display: grid;

      gap: 1em;
      min-height: 60vh;

      .lcontainer {
        min-height: 60vh;
      }

      .user-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        height: fit-content;

        .customer-box {
          padding: 10px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          display: grid;
          grid-template-columns: auto 1fr;
          height: fit-content;
          gap: 10px;
          cursor: pointer;

          .left {
            h2 {
              background-color: $secondary;
              border-radius: 5px;
              font-size: medium;
              font-weight: 600;
              height: 54px;
              display: flex;
              align-items: center;
              align-self: center;
              width: 100px;
              color: white;
              text-align: center;

              * {
                margin: auto;
              }
            }

            h4 {
              margin-top: 10px;
              font-size: small;
              font-weight: 400;
              color: $primary;
              text-align: center;
            }
          }
          .right {
            h3 {
              margin-bottom: 3px;
              font-size: small;
              font-weight: 520;
            }
            p {
              font-size: small;
              margin-bottom: 2px;
            }
          }
        }
        .customer-box:hover {
          box-shadow: 1px 1px 5px $primarylight;
        }

        .user-box {
          padding: 10px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          display: grid;
          grid-template-columns: auto 1fr;
          height: fit-content;
          gap: 10px;
          cursor: pointer;
          position: relative;

          .gd {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: x-large;
          }

          .left {
            h2 {
              background-color: antiquewhite;
              border-radius: 50%;
              font-size: large;
              font-weight: 600;
              height: 32px;
              width: 32px;
              display: grid;
              place-content: center;
              color: $secondary;
              text-align: center;
            }

            h4 {
              margin-top: 10px;
              font-size: small;
              font-weight: 400;
              color: $primary;
              text-align: center;
            }
          }
          .right {
            h3 {
              margin-bottom: 3px;
              font-size: small;
              font-weight: 520;
            }
            h4 {
              margin-bottom: 3px;
              font-size: small;
              font-weight: 520;
              color: $secondary;
            }
            p {
              font-size: x-small;
              margin-bottom: 2px;
            }
          }
        }
        .user-box:hover {
          box-shadow: 1px 1px 5px $primary;
        }
        .active {
          box-shadow: 1px 1px 5px $primary;
        }
      }

      .selected {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        padding: 1em;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: grid;
        place-content: center;

        .cdn {
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          height: fit-content;
          background-color: white;
          min-width: 30vw;

          .bar {
            background-color: $primary;
            padding: 8px;
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 10px;
            color: white;
            border-radius: 5px 5px 0 0;

            .ic {
              cursor: pointer;
            }
          }

          .other {
            padding: 1em;
          }
        }

        img {
          border: 1px solid #60606030;
          max-height: 54px;
          height: 100%;
          width: fit-content;
          display: block;
          object-fit: contain;
          margin: auto;
        }

        .div12 {
          display: grid;
          grid-template-columns: 1fr 2fr;
          border: 1px solid #60606030;
          border-collapse: collapse;

          h4 {
            font-weight: 520;
            font-size: small;
            background-color: antiquewhite;
            height: 100%;
            display: grid;
            align-items: center;
            padding: 4px;
          }

          p {
            font-size: small;
            font-weight: 400;
            height: 100%;
            display: grid;
            align-items: center;
            padding: 4px;
          }
        }

        .actions {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          padding: 10px;

          h6 {
            font-size: small;
            padding: 5px;
            background-color: $bg1;
            cursor: pointer;
            text-align: center;
            color: $secondary;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            border: 1px solid #60606010;
            font-weight: 400;
          }
        }
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 9999;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .wrap {
      max-width: 70vw;
      width: 100%;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      box-shadow: 5px #60606050;
      padding: 1em;
      position: relative;

      .loading {
        position: fixed !important;
        background-color: transparent !important;
      }

      .div21 {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1em;
      }

      hr {
        color: #60606010;
        margin: 10px 0 10px 0;
      }

      h4 {
        font-weight: 520;
        color: $secondary;
        font-size: smaller;
      }

      h6 {
        color: $secondary;
        text-align: center;
        font-size: small;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }

      .head {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;

        h3 {
          font-size: medium;
          font-weight: 520;
          color: $primary;
        }
        .fa-times {
          color: $secondary;
          cursor: pointer;
        }
        .fa-times:hover {
          color: $secondary;
        }
      }

      .button {
        background-color: $primary;
        border: none;
        border-radius: 5px;
        padding: 10px 2em;
        margin: auto;
        display: block;
        cursor: pointer;
        color: white;
        box-shadow: 1px 1px #60606030;
      }

      .button:hover {
        background-color: $primary;
      }

      .input {
        margin: 10px 0 10px 0;
        .pwd {
          position: relative;
          margin: 0px 0 0 0;

          .fa-eye {
            position: absolute;
            top: 5px;
            bottom: 0;
            height: 100%;
            right: 5px;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        label {
          margin-bottom: 1em !important;
          font-size: small;
        }

        input {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        margin: 10px 0 10px 0;
        label {
          margin-bottom: 10px;
          font-size: small;
        }

        select {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        select:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}
