@import "./variables";
@import "./mediaquery";

.clerks {
  height: 100%;
  width: 100%;
  padding: 1em;

  .singleelection {
    .welcome {
      border-bottom: 1px solid #60606030;

      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1em;

      .refresh {
        width: 32px;
        height: 32px;
        color: $accent;
        cursor: pointer;
        padding: 5px;
        background-color: white;
        border-radius: 5px;
      }

      .refresh:hover {
        color: $primary;
      }

      h1 {
        font-size: x-large;
        margin-top: 5px;
        margin-bottom: 10px;
        color: $primary;
      }

      .search {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 10px;

        .select {
          select {
            margin: auto;
            background-color: #60606020;
            padding: 1em;
            border: none;
            outline: none;
            border-radius: 44px;
            height: 44px;
          }
        }

        input {
          background-color: #60606020;
          padding: 1em;
          border: none;
          outline: none;
          border-radius: 44px;
          height: 44px;
        }

        i {
          padding: 1em;
          color: $accent;
          height: 44px;
          margin-left: -54px;
        }

        input:focus {
          outline: 1px solid orange;
        }
      }
    }
    .top {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 1em;

      .numbers {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;

        .no {
          background-color: white;
          border: 1px solid #60606010;
          border-radius: 5px;
          box-shadow: 2px 2px 4px #60606030;
          padding: 10px;
          display: grid;
          place-content: center;
          text-align: center;
          position: relative;

          .ll {
            width: 12px;
            height: 100%;
            background-color: $secondary;
            position: absolute;
            top: 0;
            left: 0;
            animation: expandWidth 1s forwards;
          }

          p {
            color: #686868;
            font-size: medium;
            margin: 5px 0;
            text-align: center;
          }

          h4 {
            font-size: 30px;
            text-align: left;
            color: $primary;
            text-align: center;
          }
        }
      }
      .public {
        padding: 1em;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: #f7e7dc;
        text-align: center;
        border: 1px solid #60606010;
        border-radius: 5px;
        box-shadow: 2px 2px 4px #60606030;

        button {
          background-color: #dca47c;
          border: 1px solid #dca47c;
          width: 100%;
          padding: 10px;
          font-size: large;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          background-color: transparent;
          color: #dca47c;
        }
      }

      .start {
        padding: 1em;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: #def9c4;
        text-align: center;
        border: 1px solid #60606010;
        border-radius: 5px;
        box-shadow: 2px 2px 4px #60606030;

        button {
          background-color: #50b498;
          border: 1px solid #50b498;
          width: 100%;
          padding: 10px;
          font-size: large;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          background-color: transparent;
          color: #50b498;
        }
      }

      .stop {
        padding: 1em;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: #def9c4;
        text-align: center;
        border: 1px solid #60606010;
        border-radius: 5px;
        box-shadow: 2px 2px 4px #60606030;

        button {
          background-color: $accent;
          border: 1px solid $accent;
          width: 100%;
          padding: 10px;
          font-size: large;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          background-color: transparent;
          color: $accent;
        }
      }
    }

    .charts {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1em;
      margin-top: 1.5em;

      .section {
        width: 100%;
        height: 100%;
        background-color: white;
        box-shadow: 1px 1px 3px #60606030;
        padding: 10px;
        position: relative;

        .br {
          width: 8px;
          height: 100%;
          background-color: $accent;
          position: absolute;
          left: 0;
          top: 0;
        }

        h3 {
          text-align: center;
          margin-bottom: 10px;
          font-size: medium;
          font-weight: 500;
          color: gray;
        }

        .gauge {
          display: grid;
          grid-template-rows: auto 1fr;
          height: 100%;

          .gg {
            display: block;
            margin-top: auto;
          }
        }

        .single {
          display: grid;
          grid-template-columns: auto 1fr;
          place-content: center;
          gap: 10px;
          margin: auto;
          height: 100%;

          .ts {
            width: 44px;
            height: 44px;
            color: $accent;
            display: block;
            margin: auto;
          }

          h4 {
            font-size: xx-large;
            font-weight: 900;
            color: gray;
            text-align: right;
            margin-bottom: 8px;
          }

          p {
            font-size: small;
            color: gray;
            text-align: right;
          }
        }

        .perf {
          display: grid;
          grid-template-rows: 1fr auto auto auto;
          gap: 10px;
          height: 100%;
          width: 100%;

          h2 {
            font-size: medium;
            font-weight: 520;
          }

          .hl {
            margin: auto;

            h4 {
              color: $accent;
              font-size: x-large;
              font-weight: bolder;
              margin-bottom: 10px;
              text-align: center;
            }

            h5 {
              font-weight: 500;
              color: gray;
              text-align: center;
            }
          }

          .div1auto {
            width: 100%;
            display: grid;
            grid-template-columns: 10px 1fr auto;
            gap: 10px;
            padding: 8px;

            div {
              width: 100%;
              height: 100%;
            }

            p {
              font-size: medium;
            }

            h6 {
              font-size: large;
              font-weight: bolder;
              color: gray;
            }
          }
        }
      }
    }

    .selectvoter {
      margin: 1.5em 0;
      display: grid;
      grid-template-columns: 2.5fr 1fr;
      gap: 1em;

      .vlist {
        background-color: white;
        border-radius: 8px;
        box-shadow: 2px 2px 5px #60606030;
        height: 100%;
        padding: 1em;

        .rs {
          height: 100%;
          display: grid;
          place-content: center;
          padding: 4em 1em;
        }

        hr {
          color: #60606010;
          margin: 10px 0 10px 0;
        }

        .utp {
          display: grid;
          grid-template-columns: 1fr auto auto;
          gap: 1em;

          h3 {
            color: $secondary;
            font-size: medium;
            font-weight: 520;
            display: flex;
            align-items: center;
          }

          p {
            font-size: small;
            color: white;
            background-color: $secondary;
            border-radius: 5px;
            border: 1px solid $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 5px 10px;

            .fa-add {
              margin-right: 5px;
            }
          }
          p:hover {
            color: $secondary;
            background-color: white;
          }

          .search {
            position: relative;

            .fa-search {
              position: absolute;
              top: 0;
              right: 5px;
              bottom: 0;
              padding: 10px;
              display: flex;
              align-items: center;
              color: #60606050;
            }

            input {
              width: 100%;
              padding: 10px;
              outline: none;
              border: 1px solid #60606050;
              font-size: small;
              border-radius: 5px;
            }
          }
        }
        .voter {
          background-color: white;
          border-radius: 8px;
          box-shadow: 2px 2px 5px #60606030;
          border: 1px solid #60606010;
          padding: 10px;
          display: grid;
          grid-template-columns: 1fr 2fr 150px;
          gap: 1em;

          h2 {
            height: 100%;
            background-color: #60606020;
            border-radius: 5px;
            color: $secondary;
            font-weight: 500;
            font-size: large;
            display: grid;
            place-content: center;
            letter-spacing: 5px;
          }

          h4 {
            color: #686868;
            font-weight: 500;
          }

          p {
            color: gray;
            font-size: small;
          }

          button {
            height: 100%;
            background-color: $secondary;
            border: 1px solid $secondary;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            transition: background-color 0.5s ease-in-out,
              color 0.5s ease-in-out;
          }
          button:hover {
            background-color: transparent;
            color: $secondary;
          }
          .active {
            background-color: $accent;
            border: 1px solid $accent;
            color: white;
          }
          .active:hover {
            color: $accent !important;
          }
        }
      }

      .selected {
        background-color: white;
        border-radius: 8px;
        box-shadow: 2px 2px 5px #60606030;
        height: 100%;
        height: 50vh;

        .tp {
          text-align: center;
          background-color: #60606010;
          padding: 8px;
          font-weight: 400;
          color: $secondary;
        }

        .cd {
          height: 100%;
          padding: 1em;
          display: grid;
          place-content: center;
          text-align: center;

          div {
            padding: 1em;
            border-radius: 8px;
            background-color: rgba($color: green, $alpha: 0.2);
          }

          h2 {
            color: $secondary;
            letter-spacing: 5px;
          }
          h4 {
            font-weight: 500;
            margin: 10px 0;
            font-size: x-large;
          }
        }
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 9999;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .wrap {
      max-width: 70vw;
      width: 100%;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      box-shadow: 5px #60606050;
      padding: 1em;
      position: relative;

      .loading {
        position: fixed !important;
        background-color: transparent !important;
      }

      .div21 {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1em;
      }

      hr {
        color: #60606010;
        margin: 10px 0 10px 0;
      }

      h4 {
        font-weight: 520;
        color: $accent;
        font-size: smaller;
      }

      h6 {
        color: $accent;
        text-align: center;
        font-size: small;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }

      .head {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;

        h3 {
          font-size: medium;
          font-weight: 520;
          color: $primary;
        }
        .fa-times {
          color: $accent;
          cursor: pointer;
        }
        .fa-times:hover {
          color: $accent;
        }
      }

      .button {
        background-color: $primary;
        border: none;
        border-radius: 5px;
        padding: 10px 2em;
        margin: auto;
        display: block;
        cursor: pointer;
        color: white;
        box-shadow: 1px 1px #60606030;
      }

      .button:hover {
        background-color: $primary;
      }

      .input {
        margin: 10px 0 10px 0;
        .pwd {
          position: relative;
          margin: 0px 0 0 0;

          .fa-eye {
            position: absolute;
            top: 5px;
            bottom: 0;
            height: 100%;
            right: 5px;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        label {
          margin-bottom: 1em !important;
          font-size: small;
        }

        input {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        margin: 10px 0 10px 0;
        label {
          margin-bottom: 10px;
          font-size: small;
        }

        select {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        select:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}

@keyframes expandWidth {
  from {
    width: 0;
  }
  to {
    width: 12px;
  }
}
