@import "./variables";
@import "./mediaquery";

.elections {
  height: 100%;
  width: 100%;
  padding: 1em;

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 0 0 5px 5px;
    position: relative;

    .loading {
      position: fixed !important;
      background-color: transparent !important;
    }

    hr {
      color: #60606010;
      margin: 10px 0 10px 0;
    }

    .utp {
      display: grid;
      grid-template-columns: 1fr auto auto auto auto auto auto auto;
      gap: 1em;

      .select-container {
        margin: auto !important;
      }

      h3 {
        color: $secondary;
        font-size: medium;
        font-weight: 520;
        display: flex;
        align-items: center;
      }

      p {
        font-size: small;
        color: white;
        background-color: $secondary;
        border-radius: 5px;
        border: 1px solid $secondary;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .fa-add {
          margin-right: 5px;
        }
      }
      p:hover {
        color: $secondary;
        background-color: white;
      }

      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          display: flex;
          align-items: center;
          color: #60606050;
          padding: 10px;
        }

        input {
          width: 100%;
          padding: 10px;
          outline: none;
          border: 1px solid #60606050;
          font-size: small;
          border-radius: 5px;
        }
      }
    }

    .div31 {
      display: grid;

      gap: 1em;
      min-height: 60vh;

      .lcontainer {
        min-height: 60vh;
      }

      .user-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        height: fit-content;

        .user-box {
          cursor: pointer;
          position: relative;
          transition: box-shadow 0.5s ease-in-out;
          overflow: hidden;

          .ele {
            background-color: white;
            box-shadow: 2px 2px 4px #60606020;
            border: 1px solid #60606020;
            border-radius: 5px;
            padding: 10px;
            overflow: hidden;

            .div1auto {
              display: grid;
              grid-template-columns: 1fr auto;
              gap: 10px;
            }

            h1 {
              font-size: medium;
              font-weight: 500;
              color: $primary;
            }

            p {
              font-size: small;
              color: #686868;
              margin: 5px 0;
            }

            .loader {
              background-color: #60606030;
              position: relative;
              border-radius: 16px;
              width: 100%;
              height: 16px;
              margin-top: 10px;

              .progress {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 30%;
                background-color: $primary;
                border-radius: 16px;
              }
              img {
                position: absolute;
                left: calc(30% - 12px);
                top: -2.5px;
                height: 24px;
                width: 24px;
              }
            }
          }
        }
        .user-box:hover {
          box-shadow: 1px 1px 5px $primary;
        }
        .active {
          box-shadow: 1px 1px 5px $primary;
        }
      }

      .selected {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        padding: 1em;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: grid;
        place-content: center;

        .cdn {
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          height: fit-content;
          background-color: white;
          min-width: 30vw;

          .bar {
            background-color: $primary;
            padding: 8px;
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 10px;
            color: white;
            border-radius: 5px 5px 0 0;

            .ic {
              cursor: pointer;
            }
          }

          .other {
            padding: 1em;
          }
        }

        img {
          border: 1px solid #60606030;
          max-height: 54px;
          height: 100%;
          width: fit-content;
          display: block;
          object-fit: contain;
          margin: auto;
        }

        .div12 {
          display: grid;
          grid-template-columns: 1fr 2fr;
          border: 1px solid #60606030;
          border-collapse: collapse;

          h4 {
            font-weight: 520;
            font-size: small;
            background-color: antiquewhite;
            height: 100%;
            display: grid;
            align-items: center;
            padding: 4px;
          }

          p {
            font-size: small;
            font-weight: 400;
            height: 100%;
            display: grid;
            align-items: center;
            padding: 4px;
          }
        }

        .actions {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          padding: 10px;

          h6 {
            font-size: small;
            padding: 5px;
            background-color: $bg1;
            cursor: pointer;
            text-align: center;
            color: $secondary;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            border: 1px solid #60606010;
            font-weight: 400;
          }
        }
      }
    }
  }

  .singleelection {
    .welcome {
      border-bottom: 1px solid #60606030;

      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1em;

      .refresh {
        width: 32px;
        height: 32px;
        color: $accent;
        cursor: pointer;
        padding: 5px;
        background-color: white;
        border-radius: 5px;
      }

      .refresh:hover {
        color: $primary;
      }

      h1 {
        font-size: x-large;
        margin-top: 5px;
        margin-bottom: 10px;
        color: $primary;
      }

      .search {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 10px;

        .select {
          select {
            margin: auto;
            background-color: #60606020;
            padding: 1em;
            border: none;
            outline: none;
            border-radius: 44px;
            height: 44px;
          }
        }

        input {
          background-color: #60606020;
          padding: 1em;
          border: none;
          outline: none;
          border-radius: 44px;
          height: 44px;
        }

        i {
          padding: 1em;
          color: $accent;
          height: 44px;
          margin-left: -54px;
        }

        input:focus {
          outline: 1px solid orange;
        }
      }
    }
    .top {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 1em;

      .numbers {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;

        .no {
          background-color: white;
          border: 1px solid #60606010;
          border-radius: 5px;
          box-shadow: 2px 2px 4px #60606030;
          padding: 10px;
          display: grid;
          place-content: center;
          text-align: center;
          position: relative;

          .ll {
            width: 12px;
            height: 100%;
            background-color: $secondary;
            position: absolute;
            top: 0;
            left: 0;
            animation: expandWidth 1s forwards;
          }

          p {
            color: #686868;
            font-size: medium;
            margin: 5px 0;
            text-align: center;
          }

          h4 {
            font-size: 30px;
            text-align: left;
            color: $primary;
            text-align: center;
          }
        }
      }
      .public {
        padding: 1em;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: #f7e7dc;
        text-align: center;
        border: 1px solid #60606010;
        border-radius: 5px;
        box-shadow: 2px 2px 4px #60606030;

        button {
          background-color: #dca47c;
          border: 1px solid #dca47c;
          width: 100%;
          padding: 10px;
          font-size: large;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          background-color: transparent;
          color: #dca47c;
        }
      }

      .start {
        padding: 1em;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: #def9c4;
        text-align: center;
        border: 1px solid #60606010;
        border-radius: 5px;
        box-shadow: 2px 2px 4px #60606030;

        button {
          background-color: #50b498;
          border: 1px solid #50b498;
          width: 100%;
          padding: 10px;
          font-size: large;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          background-color: transparent;
          color: #50b498;
        }
      }

      .stop {
        padding: 1em;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: #def9c4;
        text-align: center;
        border: 1px solid #60606010;
        border-radius: 5px;
        box-shadow: 2px 2px 4px #60606030;

        button {
          background-color: $accent;
          border: 1px solid $accent;
          width: 100%;
          padding: 10px;
          font-size: large;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
        }
        button:hover {
          background-color: transparent;
          color: $accent;
        }
      }
    }

    .charts {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1em;
      margin-top: 1.5em;

      .section {
        width: 100%;
        height: 100%;
        background-color: white;
        box-shadow: 1px 1px 3px #60606030;
        padding: 10px;
        position: relative;

        .br {
          width: 8px;
          height: 100%;
          background-color: $accent;
          position: absolute;
          left: 0;
          top: 0;
        }

        h3 {
          text-align: center;
          margin-bottom: 10px;
          font-size: medium;
          font-weight: 500;
          color: gray;
        }

        .gauge {
          display: grid;
          grid-template-rows: auto 1fr;
          height: 100%;

          .gg {
            display: block;
            margin-top: auto;
          }
        }

        .single {
          display: grid;
          grid-template-columns: auto 1fr;
          place-content: center;
          gap: 10px;
          margin: auto;
          height: 100%;

          .ts {
            width: 44px;
            height: 44px;
            color: $accent;
            display: block;
            margin: auto;
          }

          h4 {
            font-size: xx-large;
            font-weight: 900;
            color: gray;
            text-align: right;
            margin-bottom: 8px;
          }

          p {
            font-size: small;
            color: gray;
            text-align: right;
          }
        }

        .perf {
          display: grid;
          grid-template-rows: 1fr auto auto auto;
          gap: 10px;
          height: 100%;
          width: 100%;

          h2 {
            font-size: medium;
            font-weight: 520;
          }

          .hl {
            margin: auto;

            h4 {
              color: $accent;
              font-size: x-large;
              font-weight: bolder;
              margin-bottom: 10px;
              text-align: center;
            }

            h5 {
              font-weight: 500;
              color: gray;
              text-align: center;
            }
          }

          .div1auto {
            width: 100%;
            display: grid;
            grid-template-columns: 10px 1fr auto;
            gap: 10px;
            padding: 8px;

            div {
              width: 100%;
              height: 100%;
            }

            p {
              font-size: medium;
            }

            h6 {
              font-size: large;
              font-weight: bolder;
              color: gray;
            }
          }
        }
      }
    }

    // VotingResults.scss
    .voting-results {
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;
      margin-top: 1.5em;

      h2 {
        color: $secondary;
        margin: 10px 0;
        font-weight: 520;
        font-size: large;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        th,
        td {
          padding: 5px;
          border: 1px solid #ddd;
          text-align: left;
          font-size: smaller;
        }
        th {
          background-color: #f4f4f4;
        }
      }

      .bar {
        width: 100%;
        background-color: #e0e0e0;
        position: relative;

        .fill {
          height: 20px;
          background-color: $accent; // Replace $accent with your desired color
          transition: width 0.5s ease;
        }
      }
    }

    .results {
      margin-top: 1.5em;

      .cat {
        position: relative;
        background-color: white;
        box-shadow: 1px 1px 3px #60606030;
        padding: 1em;
        margin-bottom: 1em;

        h2 {
          color: $secondary;
          margin-bottom: 1em;
          font-weight: 520;
          font-size: large;
        }

        .ll {
          width: 12px;
          height: 100%;
          background-color: $primary;
          position: absolute;
          top: 0;
          left: 0;
          animation: expandWidth 1s forwards;
        }

        .user-box {
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          height: fit-content;
          gap: 10px;
          cursor: pointer;
          position: relative;
          overflow: hidden;

          h5 {
            font-size: large;
            color: $secondary;
            text-align: left;
            margin: 10px 0;
          }

          .loader {
            background-color: #60606030;
            position: relative;
            border-radius: 16px;
            width: 100%;
            height: 16px;
            margin-top: 10px;

            .progress {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 0;
              background-color: $primary;
              border-radius: 16px;
              transition: width 0.5s ease-in;
            }
            img {
              position: absolute;
              left: 0;
              top: -2.5px;
              height: 24px;
              width: 24px;
              transition: left 0.5s ease-in;
            }
          }

          .loc {
            background-color: $secondary;
            padding: 10px;
            color: white;
            text-align: center;

            h4 {
              font-weight: 500;
              font-size: medium;
            }
            p {
              font-size: small;
            }
          }

          .ot {
            padding: 1em;

            h2 {
              color: $primary;
              font-weight: 520;
              font-size: medium;
              margin-bottom: 1em;
            }

            .mycand {
              position: relative;
              padding: 10px;
              border: 1px solid #60606010;
              box-shadow: 2px 2px 4px #60606030;
              border-radius: 12px;

              h6 {
                font-weight: 400;
                color: gray;
              }

              img {
                width: 74px;
                height: 74px;
                border-radius: 50%;
                border: 1px solid #60606030;
                object-fit: cover;
                margin: 10px auto;
                display: block;
              }
              p {
                font-size: smaller;
                text-align: center;
              }
            }
          }

          .gd {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: x-large;
          }

          .left {
            position: absolute;
            top: 0;
            left: 5px;
            z-index: 99;
            h2 {
              background-color: antiquewhite;
              border-radius: 50%;
              font-size: medium;
              font-weight: 600;
              height: 24px;
              width: 24px;
              display: grid;
              place-content: center;
              text-align: center;
            }
          }

          .nom {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $secondary;
            padding: 8px;
            color: white;
            font-size: small;
          }
        }
      }
    }

    .clerks {
      background-color: white;
      padding: 1em;
      border-radius: 0 0 5px 5px;
      position: relative;

      .loading {
        position: fixed !important;
        background-color: transparent !important;
      }

      hr {
        color: #60606010;
        margin: 10px 0 10px 0;
      }

      .utp {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 1em;

        h3 {
          color: $secondary;
          font-size: medium;
          font-weight: 520;
          display: flex;
          align-items: center;
        }

        p {
          font-size: small;
          color: white;
          background-color: $secondary;
          border-radius: 5px;
          border: 1px solid $secondary;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 5px 10px;

          .fa-add {
            margin-right: 5px;
          }
        }
        p:hover {
          color: $secondary;
          background-color: white;
        }

        .search {
          position: relative;

          .fa-search {
            position: absolute;
            top: 0;
            right: 5px;
            bottom: 0;
            padding: 10px;
            display: flex;
            align-items: center;
            color: #60606050;
          }

          input {
            width: 100%;
            padding: 10px;
            outline: none;
            border: 1px solid #60606050;
            font-size: small;
            border-radius: 5px;
          }
        }
      }

      .div31 {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 1em;
        min-height: 60vh;

        .lcontainer {
          min-height: 50vh;
        }

        .user-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1em;
          height: fit-content;

          .customer-box {
            padding: 10px;
            border: 1px solid #60606010;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            display: grid;
            grid-template-columns: auto 1fr;
            height: fit-content;
            gap: 10px;
            cursor: pointer;

            .left {
              h2 {
                background-color: $secondary;
                border-radius: 5px;
                font-size: medium;
                font-weight: 600;
                height: 54px;
                display: flex;
                align-items: center;
                align-self: center;
                width: 100px;
                color: white;
                text-align: center;

                * {
                  margin: auto;
                }
              }

              h4 {
                margin-top: 10px;
                font-size: small;
                font-weight: 400;
                color: $primary;
                text-align: center;
              }
            }
            .right {
              h3 {
                margin-bottom: 3px;
                font-size: small;
                font-weight: 520;
              }
              p {
                font-size: small;
                margin-bottom: 2px;
              }
            }
          }
          .customer-box:hover {
            box-shadow: 1px 1px 5px $primarylight;
          }

          .user-box {
            padding: 10px;
            border: 1px solid #60606010;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            display: grid;
            grid-template-columns: auto 1fr;
            height: fit-content;
            gap: 10px;
            cursor: pointer;

            .left {
              h2 {
                background-color: $secondary;
                border-radius: 5px;
                font-size: large;
                font-weight: 600;
                height: 54px;
                line-height: 54px;
                width: 54px;
                color: white;
                text-align: center;
              }

              h4 {
                margin-top: 10px;
                font-size: small;
                font-weight: 400;
                color: $primary;
                text-align: center;
              }
            }
            .right {
              h3 {
                margin-bottom: 3px;
                font-size: small;
                font-weight: 520;
              }
              p {
                font-size: small;
                margin-bottom: 2px;
              }
            }
          }
          .user-box:hover {
            box-shadow: 1px 1px 5px $primary;
          }
          .active {
            box-shadow: 1px 1px 5px $primary;
          }
        }

        .selectedclerk {
          padding: 1em;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          height: fit-content;

          img {
            border: 1px solid #60606030;
            max-height: 54px;
            height: 100%;
            width: fit-content;
            display: block;
            object-fit: contain;
            margin: auto;
          }

          h4 {
            font-weight: 520;
            font-size: small;
          }

          p {
            font-size: small;
            margin: 8px 0 8px 0;
          }

          .actions {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            margin-top: 2em;

            h6 {
              font-size: small;
              padding: 5px;
              background-color: $bg1;
              cursor: pointer;
              text-align: center;
              color: $secondary;
              box-shadow: 1px 1px 5px #60606030;
              border-radius: 5px;
              border: 1px solid #60606010;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 9999;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .wrap {
      max-width: 70vw;
      width: 100%;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      box-shadow: 5px #60606050;
      padding: 1em;
      position: relative;

      .loading {
        position: fixed !important;
        background-color: transparent !important;
      }

      .div21 {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1em;
      }

      hr {
        color: #60606010;
        margin: 10px 0 10px 0;
      }

      h4 {
        font-weight: 520;
        color: $accent;
        font-size: smaller;
      }

      h6 {
        color: $accent;
        text-align: center;
        font-size: small;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }

      .head {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;

        h3 {
          font-size: medium;
          font-weight: 520;
          color: $primary;
        }
        .fa-times {
          color: $accent;
          cursor: pointer;
        }
        .fa-times:hover {
          color: $accent;
        }
      }

      .button {
        background-color: $primary;
        border: none;
        border-radius: 5px;
        padding: 10px 2em;
        margin: auto;
        display: block;
        cursor: pointer;
        color: white;
        box-shadow: 1px 1px #60606030;
      }

      .button:hover {
        background-color: $primary;
      }

      .input {
        margin: 10px 0 10px 0;
        .pwd {
          position: relative;
          margin: 0px 0 0 0;

          .fa-eye {
            position: absolute;
            top: 5px;
            bottom: 0;
            height: 100%;
            right: 5px;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        label {
          margin-bottom: 1em !important;
          font-size: small;
        }

        input {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        margin: 10px 0 10px 0;
        label {
          margin-bottom: 10px;
          font-size: small;
        }

        select {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        select:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}

@keyframes expandWidth {
  from {
    width: 0;
  }
  to {
    width: 12px;
  }
}
